import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
const store = new Vuex.Store({
    state: {
        userData: [],
        currentEntity: null,
        defaultEnterprise: '',
        userHeadImg: '',
        defaultEntName: '',
        headerTransparent: false,
    },
    mutations: {
        // 存储数据到浏览器
        setUserData(state, data) {
            state.userData = data;
            localStorage.setItem('userData', JSON.stringify(data));
        },
        setTransparent(state, data) {
            state.headerTransparent = data;
        },
    },
    actions: {
        // 请求时，获取本地存储数据，并进行遍历赋值
        async getUserData({ commit }, data) {
            // const userData = localStorage.getItem('userData');
            commit("setUserData", data);
            localStorage.setItem('userData', JSON.stringify(data));
            console.log('data',data);
            // if (userData) {
            //     commit("setUserData", JSON.parse(userData));
            // } else {
            //     commit("setUserData", data);
            //     localStorage.setItem('userData', JSON.stringify(data));
            // }
        }
    },
    getters: {
        getUser(state) {
            return state.userData;
        },
        currentEntity(state) {
            return state.currentEntity;
        },
        headerTransparent(state) {
            return state.headerTransparent;
        }
    }
})

export default store